import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '@rapid/dhtmlx-scheduler/css/scheduler.css';
import DHTMLXScheduler, { DAYS } from '@rapid/dhtmlx-scheduler';
import { useCallback, useEffect, useState } from 'react';
import { openDialog } from '../components/dialog/dialog';
import TimeLogDialog from '../components/dialog/time-log-dialog';
import { createListItemInstance, getListItem } from '@rapid/sdk/lib/store/list-item';
import { store, useApplicationStore, useList } from '@rapid/sdk';
import { proxy } from 'valtio';
import { durationFromDiff as hoursDiff } from '../utils/dates';
import { useSchedulerContext } from '../scheduler-context';
import { useTimeLogEvents } from '../hooks/use-time-log-events';
import { setCurrentTask } from '../store/store';
import EventRender from '../components/event-render/event-render';
export default function Scheduler() {
    const [now] = useState(() => new Date());
    const scheduler = useSchedulerContext();
    const [, listProxy] = useList('Time Logs');
    const [events, viewProxy] = useTimeLogEvents();
    const [appData] = useApplicationStore();
    const refreshView = () => {
        viewProxy.get();
    };
    useEffect(refreshView, []);
    const onEventRender = useCallback((scheduler, event) => {
        return _jsx(EventRender, { scheduler: scheduler, event: event });
    }, []);
    const onEventCreated = useCallback((scheduler, id) => {
        const event = scheduler.getEvent(id);
        event.readonly = true;
        scheduler.updateEvent(id);
        const isNew = true;
        const hours = hoursDiff(event.start_date, event.end_date);
        const item = createListItemInstance(listProxy, {
            date: event.start_date,
            hours,
            task_id: appData.selectedTaskId,
            __metadata: {
                type: listProxy.ListNameSingular,
            },
        }, isNew, event.id.toString());
        item.$data.event = event;
        if (!store.listItems[listProxy.ListName]) {
            store.listItems[listProxy.ListName] = proxy({});
        }
        store.listItems[listProxy.ListName][event.id.toString()] = item;
    }, [appData.selectedTaskId]);
    const onEventChanged = useCallback((scheduler, id) => {
        const event = scheduler.getEvent(id);
        const hours = hoursDiff(event.start_date, event.end_date);
        const item = getListItem(listProxy, id.toString());
        item.hours = hours;
        item.date = event.start_date;
        item.$data.event = event;
    }, []);
    const onOpenLightbox = (id) => {
        const onOpenChange = () => {
            setCurrentTask();
            refreshView();
        };
        const onCancel = () => {
            const item = getListItem(listProxy, id);
            if (item.$state.new) {
                scheduler.deleteEvent(id);
                item.delete();
            }
            onOpenChange();
        };
        const onSave = () => {
            onOpenChange();
        };
        openDialog(TimeLogDialog, {
            onCancel: onCancel,
            onSave: onSave,
            closeOnOutsideClick: false,
            dialogProps: {
                id,
                scheduler,
            },
        });
    };
    return (_jsxs("div", { className: "SchedulerContainer container", children: [_jsx("button", { onClick: refreshView, children: _jsx("i", { className: "fas fa-fw fa-arrows-rotate" }) }), _jsx(DHTMLXScheduler, { scheduler: scheduler, events: events, initialStartOfWeek: now, hideDays: [DAYS.SUNDAY, DAYS.SATURDAY], firstHour: 5, lastHour: 19, hourScale: 88, timestep: 1, onEventRender: onEventRender, onOpenLightbox: onOpenLightbox, onEventCreated: onEventCreated, onEventChanged: onEventChanged, onEventDrag: onEventChanged, dblclickDetails: true, dblclickCreate: true, dragCreate: true, lockEventDuration: true })] }));
}
