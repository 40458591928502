import { jsx as _jsx } from "react/jsx-runtime";
import { DialogEvent } from '@rapid/convergence/lib/Dialog/DialogEventListener';
import { useRef } from 'react';
import HotKeys from 'react-hot-keys';
import useOnClickOutside from '../../hooks/use-on-click-outside';
import './dialog.scss';
export default function Dialog({ Component, dialogProps, onSave, onCancel, className, closeOnEscape = true, closeOnOutsideClick = true, outsideClickOverrides = ['layers'], }) {
    const dialogRef = useRef(null);
    useOnClickOutside(dialogRef, closeOnOutsideClick ? onCancel : nop, outsideClickOverrides);
    return (_jsx(HotKeys, { keyName: "Escape", onKeyDown: closeOnEscape ? onCancel : nop, children: _jsx("div", { className: `Dialog${className ? ` ${className}` : ''}`, ref: dialogRef, children: _jsx(Component, { ...dialogProps, onSave: onSave, onCancel: onCancel }) }) }));
}
export function openDialog(dialog, options) {
    const event = new DialogEvent(Dialog, {
        Component: dialog,
        ...options,
    });
    document.dispatchEvent(event);
}
function nop() { }
