import { Adaptive } from '@rapid/adaptive-core/lib/adaptive';
import controls from '@rapid/adaptive-controls';
import actions from '@rapid/adaptive-actions';
import { connectors } from '@rapid/adaptive-connectors';
import { toast } from 'react-toastify';
const notify = (content, options) => {
    toast(content, options);
};
const generateTimeLogDocument = (id, fetch) => ({
    type: 'form',
    head: {
        title: 'Time Log',
        states: {
            default: {
                label: 'Default',
                state: {},
            },
        },
        type: 'Form',
        connections: {
            timelog_connection: {
                id: 'timelog_connection',
                connectorId: 'RAPID Platform',
                method: 'Single Item',
                args: {
                    fetch,
                    list: {
                        listName: 'Time Logs',
                        table: 'time_log',
                    },
                },
                elements: [
                    'Time Logs:date:ZzCuE',
                    'Time Logs:task_id:isXkM',
                    'Time Logs:description_of_activities:sSGW9',
                    'Time Logs:hours:RhZYA',
                ],
                validation: ['yWw_e'],
                children: [],
                isLegacy: false,
            },
        },
        conditions: {
            yWw_e: {
                id: 'yWw_e',
                label: 'Must have task',
                isValidation: true,
                triggers: {},
                forceValidationMessages: true,
                watchers: [
                    {
                        id: 'sboAi',
                        actionId: 'validate',
                        expression: {
                            id: '-wvQi',
                            rules: [
                                {
                                    id: 'Q568VCv0lc',
                                    targetId: 'Time Logs:task_id:isXkM',
                                    type: 'number',
                                    state: 'data',
                                    operator: 'greater',
                                    value: 0,
                                    validation: {
                                        invalidMessage: 'A Task is required to create a Time Log',
                                    },
                                },
                            ],
                            combinator: 'or',
                        },
                    },
                ],
            },
        },
    },
    body: {
        $type: 'Layout.Section',
        id: 'root',
        $children: [
            {
                id: 'Time Logs:task_id:isXkM',
                description: 'Lookup',
                title: 'Task',
                attributes: {
                    label: 'Task',
                    listName: 'Time Logs',
                    columnName: 'task_id',
                    listNameSingular: 'Time Log',
                    tableName: 'time_log',
                    lookupList: 'Tasks',
                    lookupField: 'title',
                },
                connection: {
                    id: 'timelog_connection',
                    path: [
                        {
                            path: '$["task_id"]',
                            id: 'timelog_connection',
                            key: 'data',
                        },
                    ],
                },
                $type: 'Input.Lookup',
                $children: [],
                conditions: [],
            },
            {
                $type: 'Layout.Section',
                title: 'Section',
                id: 'Layout.Section_54-n',
                attributes: {
                    alignChildren: 'stretch',
                    justifyChildren: 'stretch',
                    heading: false,
                    direction: 'horizontal',
                },
                $children: [
                    {
                        id: 'Time Logs:date:ZzCuE',
                        description: 'DateTime',
                        title: 'Date',
                        attributes: {
                            label: 'Date',
                            listName: 'Time Logs',
                            columnName: 'date',
                            listNameSingular: 'Time Log',
                            tableName: 'time_log',
                            showTime: true,
                        },
                        connection: {
                            id: 'timelog_connection',
                            path: [
                                {
                                    path: '$["date"]',
                                    id: 'timelog_connection',
                                    key: 'data',
                                },
                            ],
                        },
                        $type: 'Input.DateTime',
                        $children: [],
                        conditions: [],
                    },
                    {
                        id: 'Time Logs:hours:RhZYA',
                        description: 'Number',
                        title: 'Hours',
                        attributes: {
                            label: 'Hours',
                            listName: 'Time Logs',
                            columnName: 'hours',
                            listNameSingular: 'Time Log',
                            tableName: 'time_log',
                        },
                        connection: {
                            id: 'timelog_connection',
                            path: [
                                {
                                    path: '$["hours"]',
                                    id: 'timelog_connection',
                                    key: 'data',
                                },
                            ],
                        },
                        $type: 'Input.Number',
                        $children: [],
                        conditions: [],
                    },
                ],
                conditions: [],
            },
            {
                id: 'Time Logs:description_of_activities:sSGW9',
                description: 'Note',
                title: 'Description of Activities',
                attributes: {
                    label: 'Description of Activities',
                    listName: 'Time Logs',
                    columnName: 'description_of_activities',
                    listNameSingular: 'Time Log',
                    tableName: 'time_log',
                    multiline: true,
                },
                connection: {
                    id: 'timelog_connection',
                    path: [
                        {
                            path: '$["description_of_activities"]',
                            id: 'timelog_connection',
                            key: 'data',
                        },
                    ],
                },
                $type: 'Input.MultiLineText',
                $children: [],
                conditions: [],
            },
        ],
        attributes: {},
        conditions: [],
    },
    id: `timelog-${id}`.toLowerCase(),
});
const createDocument = generateTimeLogDocument('Create', false);
const updateDocument = generateTimeLogDocument('Update', true);
const adaptive = Adaptive.create({
    debug: false,
    name: 'Adaptive Designer',
    documents: [
        {
            document: createDocument,
        },
        {
            document: updateDocument,
        },
    ],
    library: {
        controls,
        actions,
        connectors,
        notify,
    },
});
export default adaptive;
