import { useViewItems } from '@rapid/sdk';
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { proxy } from 'valtio';
export function useTimeLogEvents(view = 'All') {
    const [viewSnap, viewProxy] = useViewItems('Time Logs', view);
    const events = useMemo(function mapEventsOnMount() {
        const viewItems = viewSnap.map((i) => i);
        const ev = viewItems.reduce((prev, curr) => {
            const local_start_date = DateTime.fromISO(curr.date);
            const offset = local_start_date.setZone().offset - local_start_date.offset;
            const start_date = local_start_date.plus({ minutes: offset });
            let end_date;
            end_date = start_date.plus({ hours: curr.hours });
            const item = getListItem(viewProxy.list, curr.id);
            const newCurr = proxy({
                text: curr.task,
                start_date: start_date.toJSDate(),
                end_date: end_date.toJSDate(),
                id: curr?.id ?? nanoid(),
                active: false,
                // readonly: true,
                ...item,
            });
            prev.push(newCurr);
            return prev;
        }, []);
        return ev;
    }, [viewSnap]);
    return [events, viewProxy];
}
