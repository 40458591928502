import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { DesignerLayout } from '@rapid/convergence';
import { useApplicationStore } from '@rapid/sdk';
import { Outlet } from 'react-router-dom';
import Logo from '../components/logo';
import TasksList from '../components/tasks-list/tasks-list';
import '../styles/routes/root-route-styles.scss';
import '../styles/elements/menubar-elements.scss';
const placeholderMenubar = [
    {
        type: 'item',
        key: 'placeholder',
        titleRender: () => _jsx(_Fragment, {}),
        leaf: true,
    },
];
export default function ConvergenceLayout() {
    const [appData] = useApplicationStore();
    return (_jsx(DesignerLayout, { keyboardShortcuts: appData.keyboardShortcuts, routes: appData.routes, logo: _jsx(Logo, {}), menubar: placeholderMenubar, farMenubar: appData?.farMenubar, sidebar: _jsx(TasksList, {}), children: _jsx(Outlet, {}) }));
}
