import { jsx as _jsx } from "react/jsx-runtime";
import { useListItem } from '@rapid/sdk';
import Show from '../show/show';
export default function EventTask(props) {
    return (_jsx(Show, { when: !!props.id, fallback: _jsx("div", { className: "TaskTitle Empty", children: "Select a task" }), children: _jsx(GuardedEventTask, { id: props.id }) }));
}
function GuardedEventTask(props) {
    const [taskSnap] = useListItem('Tasks', props.id);
    return _jsx("div", { className: "TaskTitle", children: taskSnap.title });
}
