import { store } from '@rapid/sdk';
import { proxy } from 'valtio';
import { openDialog } from '../components/dialog/dialog';
import TimeLogDialog from '../components/dialog/time-log-dialog';
const THEME_KEY = 'rapid::time-logs::theme';
export function getLocalStorageTheme() {
    const theme = localStorage.getItem(THEME_KEY);
    if (typeof theme === 'string') {
        const html = document.querySelector('html');
        html.classList.add(theme);
    }
}
export function toggleTheme() {
    const html = document.querySelector('html');
    html.classList.toggle('dark-theme');
    if (html.classList.contains('dark-theme')) {
        localStorage.setItem(THEME_KEY, 'dark-theme');
    }
    else {
        localStorage.removeItem(THEME_KEY);
    }
}
const tasksStore = proxy({
    currentView: 'My Tasks',
    params: {},
    keyboardShortcuts: {},
    routes: {},
    menubar: [],
    farMenubar: [
        {
            type: 'item',
            iconClass: 'fal fa-fw fa-plus',
            label: 'Create',
            leaf: true,
            onClick() {
                const id = window.scheduler.addEventNow();
                openDialog(TimeLogDialog, {
                    dialogProps: {
                        id,
                    },
                });
            },
            key: 'create',
        },
        {
            type: 'separator',
            key: 'sep-1',
            leaf: true,
        },
        {
            type: 'item',
            iconClass: 'fal fa-fw fa-moon-over-sun theme',
            leaf: true,
            onClick() {
                toggleTheme();
            },
            key: 'theme',
        },
    ],
});
export default function initSDKStore() {
    store.applicationData = tasksStore;
}
export function setCurrentView(view) {
    store.applicationData.currentView = view;
}
export function setCurrentTask(id) {
    if (store.applicationData.selectedTaskId === id) {
        store.applicationData.selectedTaskId = undefined;
        return;
    }
    store.applicationData.selectedTaskId = id;
}
export async function getSiteTheme() {
    const [, _tenant] = location.pathname.replace(/^\//, '').split('/');
    if (!_tenant)
        return;
    if (document.querySelector('style#rapid-theme-style'))
        return;
    const style = document.createElement('style');
    style.id = 'rapid-theme-style';
    const result = await fetch(`https://cdn.rapidplatform.com/themes/${_tenant.toLocaleLowerCase()}/theme.css`).then((res) => res.text());
    style.innerHTML = result;
    document.head.appendChild(style);
}
