import { jsx as _jsx } from "react/jsx-runtime";
import { RapidApplication } from '@rapid/sdk/lib/ui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RootLayout from './routes/root-route';
import Scheduler from './routes/scheduler';
import SchedulerContext from './scheduler-context';
export default function App() {
    return (_jsx(DndProvider, { backend: HTML5Backend, children: _jsx(SchedulerContext, { children: _jsx(RapidApplication, { subRoutes: [
                    {
                        id: 'root-layout',
                        path: '',
                        element: _jsx(RootLayout, {}),
                        children: [
                            {
                                id: 'scheduler',
                                index: true,
                                element: _jsx(Scheduler, {}),
                            },
                        ],
                    },
                ] }) }) }));
}
