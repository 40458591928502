import { useEffect } from 'react';
export default function useOnClickOutside(ref, handler, excludedContainerIds = []) {
    useEffect(() => {
        const listener = (event) => {
            const containers = excludedContainerIds.map((id) => document.getElementById(id));
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current ||
                ref.current.contains(event.target) ||
                containers.some((container) => container?.contains(event.target))) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
