import { jsx as _jsx } from "react/jsx-runtime";
import '@rapid/convergence/dist/convergence.css';
import '@rapid/sdk/css/sdk.css';
import ReactDOM from 'react-dom/client';
import App from './app';
import initSDKStore, { getLocalStorageTheme, getSiteTheme } from './store/store';
import './styles/index.scss';
import './styles/radiux.css';
import { onLogin } from '@rapid/sdk';
getLocalStorageTheme();
onLogin(async () => {
    getSiteTheme();
});
initSDKStore();
getSiteTheme();
ReactDOM.createRoot(document.getElementById('root')).render(_jsx(App, {}));
