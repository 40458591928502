import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const schedulerContext = createContext({});
schedulerContext.displayName = 'Scheduler Context';
const SchedulerProvider = schedulerContext.Provider;
const scheduler = window.scheduler;
export default function SchedulerContext(props) {
    return _jsx(SchedulerProvider, { value: { scheduler }, children: props.children });
}
export function useSchedulerContext() {
    const context = useContext(schedulerContext);
    return context.scheduler;
}
