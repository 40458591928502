import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getListItem } from '@rapid/sdk/lib/store/list-item';
import { toast } from 'react-toastify';
import adaptive from '../adaptive/adaptive-instance';
import Document from '../adaptive/document';
import Show from '../show/show';
export default function TimeLogDialog(props) {
    const isUpdate = typeof props.id === 'string';
    const onSave = () => {
        const doc = adaptive.documents.getById(isUpdate ? 'timelog-update' : 'timelog-create');
        const rootElement = doc.rootElements.getById(props.id.toString());
        if (rootElement.validate()) {
            const item = getListItem('Time Logs', props.id);
            toast
                .promise(item.saveAsync(), {
                pending: 'Saving',
                success: 'Saved',
            })
                .then(() => {
                if (!isUpdate) {
                    props.onCancel();
                }
            });
        }
    };
    const onDelete = () => {
        if (typeof props.id === 'string') {
            props.scheduler.deleteEvent(props.id);
        }
        const item = getListItem('Time Logs', props.id);
        item.delete();
        props.onCancel();
    };
    return (_jsxs("div", { className: "TimeLogDialog", children: [_jsx("button", { onClick: props.onCancel, children: _jsx("i", { className: "fal fa-fw fa-xmark" }) }), _jsx(Show, { when: typeof props.id === 'string', fallback: _jsx(Document, { documentId: "timelog-create", namespace: "normal", id: props.id.toString(), state: {
                        timelog_connection: {
                            id: props.id,
                        },
                    } }), children: _jsx(Document, { documentId: "timelog-update", namespace: "normal", id: props.id, state: {
                        timelog_connection: {
                            id: props.id,
                        },
                    } }) }), _jsxs("div", { className: "Actions", children: [_jsx("button", { className: "delete", onClick: onDelete, disabled: !isUpdate, children: "Delete" }), _jsx("button", { className: "create", onClick: onSave, children: isUpdate ? 'Save' : 'Create' })] })] }));
}
