import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as Select from '@radix-ui/react-select';
import chroma from 'chroma-js';
import { useCallback } from 'react';
import './choice-select.scss';
export function createOpposingColor(inputColor, bgColor) {
    const chromaInputColor = chroma(inputColor);
    const chromaBGColor = chroma(bgColor);
    let chromaOpposingColor = chroma(chromaInputColor);
    //0 = black; 1 = white
    const bgLuminance = chromaBGColor.luminance();
    for (let i = 0; i < 10; i++) {
        const contrast = chroma.contrast(chromaBGColor, chromaOpposingColor);
        if (contrast > 7)
            break;
        if (bgLuminance >= 0.5)
            chromaOpposingColor = chromaOpposingColor.darken(0.7);
        else
            chromaOpposingColor = chromaOpposingColor.brighten(0.7);
    }
    return chromaOpposingColor;
}
function ChoiceSelect({ id, placeholder, defaultValue, onChange, value, choices, idKey = 'Text', labelKey = 'Text', colorKey = 'AccentColour', }) {
    const onChoiceChanged = useCallback(function (value) {
        onChange(choices?.find((c) => c[idKey ?? 'id'] === value));
    }, [onChange]);
    return (_jsx("div", { className: `choice-select${value ? ' selected' : ' unselected'}`, children: _jsxs(Select.Root, { value: value?.[idKey ?? 'id'] ?? '', defaultValue: defaultValue?.[idKey ?? 'id'], onValueChange: onChoiceChanged, children: [_jsxs(Select.Trigger, { id: id, className: `input-small trigger flex-align-row flex-between gap-normal p${value?.[idKey ?? 'id'] ? ' value' : ' placeholder'}`, children: [_jsxs(Select.SelectValue, { placeholder: placeholder, children: [!!value?.[idKey ?? 'id'] && value?.[idKey ?? 'id'], !value?.[idKey ?? 'id'] && placeholder] }), _jsx(Select.SelectIcon, { children: _jsx("i", { className: "fal fa-fw fa-angle-down" }) })] }), _jsxs(Select.Content, { className: "select-content", children: [_jsx(Select.ScrollUpButton, {}), _jsx(Select.Viewport, { className: "flex-column gap-normal", children: choices?.map((choice) => {
                                const label = typeof choice === 'string' ? choice : choice?.[labelKey];
                                const id = typeof choices === 'string' ? choice : choice?.[idKey];
                                return (_jsx(Select.Item, { value: id, className: "choice-item", onPointerDownCapture: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return e;
                                    }, children: _jsx(Select.ItemText, { children: _jsx("p", { className: "p truncate", children: label }) }) }, id));
                            }) }), _jsx(Select.ScrollDownButton, {})] })] }) }));
}
export default ChoiceSelect;
